import React, { useState } from 'react'
import { IntlProvider } from 'react-intl'
import intl from '../../../intl.json'
import cookies from 'js-cookie'

// interface ContextType {
//   locale: string
//   onChangeLanguage: any
// }

interface IntlContextType {
  locale: string;
  onChangeLanguage: (args: any) => void;
}

export const Context = React.createContext<IntlContextType | null>(null)

const IntlWrapper = (props: any) => {
	const currentLanguageFromCookies = cookies.get('current-language')

	const local = currentLanguageFromCookies
		? currentLanguageFromCookies
		: global.navigator?.language || 'fr-FR'

	const language = local?.split(/[-_]/)[0] || 'fr'
	// const intl = useIntl()

	const [locale, setLocale] = useState(local)
	const [messages, setMessages] = useState(intl[language])
	const onChangeLanguage = (e) => {
		const newLocale = e.target.value
		setLocale(newLocale)
		cookies.set('current-language', newLocale, {
			path: '/',
			sameSite: 'none',
			secure: true,
		})
		if (newLocale === 'fr-FR') {
			setMessages(intl['fr'])
		} else {
			setMessages(intl['en'])
		}
	}

	return (
		<Context.Provider value={{ locale, onChangeLanguage }}>
			{/* @ts-ignore */}
			<IntlProvider locale={locale} messages={messages}>
				{/* {this?.pageProps?.children} */}
				{props.children}
			</IntlProvider>
		</Context.Provider>
	)
}

export default IntlWrapper
