/* eslint-disable */
// @ts-nocheck
import React from 'react'
import App from 'next/app'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ApolloLink } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { createHttpLink } from 'apollo-link-http'

import { ApolloProvider } from '@apollo/react-hooks'
import { createUploadLink } from 'apollo-upload-client'
import getConfig from 'next/config'
import fetch from 'node-fetch'
import cookies from 'js-cookie'
import { IntlProvider } from 'react-intl'
import { StyledEngineProvider, CssBaseline, NoSsr } from '@mui/material'
import { Theme, ThemeProvider } from '@mui/material/styles'
import intl from '../intl'
import { theme } from '@theme'
import { SnackbarProvider } from 'notistack'
import 'moment/locale/fr'
import lowerCase from 'lodash/lowerCase'
import Button from '@mui/material/Button'
import IntlWrapper from '@molecules/IntlWrapper'
// import { pdfjs } from 'react-pdf'

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
// 	'pdfjs-dist/build/pdf.worker.min.mjs',
// 	import.meta.url,
// ).toString()

// import Router, { useRouter } from 'next/router'
// const router = useRouter();
// const { locale } = router;
// const { i18n } = useTranslation();

// useEffect(() => {
// 	i18n.changeLanguage(locale);
// }, [locale]);
declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

class MyApp extends App {
	client: any

	static async getInitialProps(context) {
		return {}
	}

	shouldComponentUpdate(nextProps) {
		if (this.props.Component === nextProps.Component) {
			return false
		}
		return true
	}

	componentDidMount() {
		// Remove the server-side injected CSS.
		const jssStyles = document.querySelector('#jss-server-side')
		if (jssStyles) {
			jssStyles.parentElement.removeChild(jssStyles)
		}
	}

	render() {
		const { Component, pageProps } = this.props

		const {
			publicRuntimeConfig: { appId, graphQLUrl },
		} = getConfig()

		const options = {
			uri: graphQLUrl,
			fetch: (...args) => {
				// console.log('IN FETCH => ', args)
				// let pathName =  window.location.pathname.split('/')[1]

				// if (['fr', 'en'].includes(pathName)) pathName = window.location.pathname.split('/')[2]
				const currentRole = window.location.pathname.split('/')[1]

				let t = cookies.get(`token-${lowerCase(currentRole)}`)

				// console.log(
				// 	'try to get the roken here => ',
				// 	`token-${lowerCase(currentRole)}`,
				// )

				// console.log('cookies )> ', cookies.get('currentLanguage'))
				// console.log('cookies 2 )> ', cookies.get('current-language'))
				// console.log('cookie token', t)
				// console.log('args )> ',window.location.pathname, window.location.pathname.split('/')[1])

				let currentLanguage = cookies.get('current-language')
				// console.log('token in cookies ? ', t)
				// console.log('all cookies => ', cookies)
				if (t) args[1].headers['Authorization'] = `${t}`

				// const intlContext = useContext(Context)

				// console.log(
				// 	'before set accept language',
				// 	Context.Provider.
				// )
				// if (intlContext.locale)
					args[1].headers['Accept-Language'] = currentLanguage

				// console.log('args header => ', args[1].headers)
				if (currentLanguage)
					args[1].headers['Current-Language'] = `${currentLanguage}`

				// args[1].headers['X-Parse-Application-Id'] = appId
				return fetch(...args)
			},
		}

		const httpLink = ApolloLink.split(
			(operation) => operation.getContext().hasUpload,
			createUploadLink(options),
			// new BatchHttpLink(options),
		)

		const fetchPolicy = process.browser ? 'cache-first' : 'cache-only'

		if (!this.client) {
			this.client = new ApolloClient({
				cache: new InMemoryCache(),
				defaultOptions: {
					watchQuery: { fetchPolicy },
					query: {
						fetchPolicy,
					},
				},
				link: createUploadLink(options), // httpLink, // createHttpLink({ ...options }), //httpLink, //
			})
		}

		console.log('navigator.language', global.navigator?.language)

		const language = global.navigator?.language?.split(/[-_]/)[0] || 'fr'
		// const language = 'fr'

		return (
			<SnackbarProvider preventDuplicate>
				<ApolloProvider client={this.client}>
					{/* <IntlProvider
						locale={global.navigator?.language || 'fr'}
						messages={intl[language]}
						defaultLocale='fr'
					> */}
					<IntlWrapper>
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={theme}>
								<CssBaseline />
								<NoSsr>
									{/* <Button>French</Button>
									<Button>English</Button> */}
									<Component {...pageProps} />
								</NoSsr>
							</ThemeProvider>
						</StyledEngineProvider>
					</IntlWrapper>
					{/* </IntlProvider> */}
				</ApolloProvider>
			</SnackbarProvider>
		)
	}
}

export default MyApp
