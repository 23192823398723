// @ts-nocheck
import {
	createTheme,
	responsiveFontSizes,
	adaptV4Theme,
} from '@mui/material/styles'
import { brown, blueGrey, blue } from '@mui/material/colors'

export const theme = responsiveFontSizes(
	createTheme({
		'@global': {
			body: {
				backgroundColor: '#FFFFFF',
				'background-color': '#FFFFFF',
				background: {
					default: '#FFFFFF',
				},
			},
		},
		palette: {
			background: {
				default: '#FFFFFF',
			},
			primary: {
				main: brown[500],
				light: brown[400],
				dark: brown[600],
			},
			primaryLight: {
				main: brown[200],
				light: brown[100],
				dark: brown[300],
			},
			primaryDark: {
				main: brown[800],
				light: brown[900],
				dark: brown[700],
			},
			helloColop: {
				main: blue[200],
				light: blue[100],
				dark: blue[300],
			},
			secondary: {
				main: blueGrey[500],
				light: blueGrey[400],
				dark: blueGrey[700],
			},
		},
		cssLabel: {
			color: 'secondary',
		}, // Test
		spacing: 10,
		'background-color': '#FFFFFF',
		shape: {
			borderRadius: 0,
			// borderColor: 'secondary'
		},
		typography: {
			// fontFamily: "'NextAtomic', sans-serif",
			h2: {
				fontSize: 60,
				fontWeight: 300,
				letterSpacing: -0.5,
			},
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 80,
					},
				},
			},
			MuiButtonBase: {
				styleOverrides: {
					root: {
						borderRadius: 80,
					},
				},
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						borderRadius: 80,
					},
				},
			},
			MuiCard: {
				defaultProps: {
					elevation: 3,
				},
				styleOverrides: {
					root: {
						borderRadius: 10,
						backgroundColor: '#FAFAFA',
						// boxShadow: 'none',
						// boxShadow: '0px 2px 2px 0px',
					},
				},
			},
			// MuiPaper: {
			// 	elevation: 20,
			// 	styleOverrides: {
			// 		root: {
			// 			borderRadius: 10,
			// 			backgroundColor: '#FAFAFA',
			// 			// boxShadow: 20,
			// 		},
			// 	},
			// },
			MuiInputLabel: {
				root: {
					color: brown[500],
				},
				styleOverrides: {
					root: {
						color: brown[500],
						'&.Mui-focused': {
							// increase the specificity for the pseudo class
							color: brown[500],
							// borderRadius:
						},
					},
				},
			},
			MuiFilledInput: {
				styleOverrides: {
					root: {
						backgroundColor: '#EFEFEF',
						// borderRadius: 8
					},
				},
			},
			// MuiButton: {
			// 	filled: {
			// 		boxShadow: 'none !important',
			// 	},
			// 	filledSecondary: {
			// 		color: '#FFF',
			// 	},
			// },
			MuiCssBaseline: {
				styleOverrides: {
					'@global': {
						body: {
							backgroundColor: '#FFFFFF',
						},
					},
				},
			},
		},
	}),
)